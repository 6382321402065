import React from "react"
import toc from "../../../content/toc.json"

const PrivacyPage = () => {
  return (
    <div>
      {toc.content.map(part => {
        return (
          <div key={part.title}>
            <h2>{part.title}</h2>
            <p>{part.text}</p>
          </div>
        )
      })}
    </div>
  )
}
export default PrivacyPage
